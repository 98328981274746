import { Prop, Component, Vue } from 'vue-property-decorator';
import { EventBus } from '@/utilities/eventBus/EventBus';
import LooseObject from '@/models/Objects/LooseObject';
import store from '@/store';

@Component
export default class TableInput extends Vue {
    @Prop() public props!: LooseObject;
    private searchText: string = '';
    private newRow: LooseObject = {};
    private editableData: LooseObject[] = [];
    private currentlyEditable: string = '';
    private editableTable: boolean = false;

    private headStyleObj = {
        'border-bottom': '1px',
        'font-size': '1.1vw',
        'height': '40px',
        'min-height': '20px',
    };

    private bodyStyleObj = {
        'text-align': 'center',
        'box-shadow': '0px 0px 9px #00000029',
        'border-radius': '20px',
        'height': '10vw',
        'min-height': '90px',
        'font-size': '3vw',
        'font-weight': 'bold',
    };

    protected created() {

        EventBus.$on('requestValue', this.submitValue);
        /* tslint:disable:no-string-literal */
        if (this.props.actionColumns &&
            this.props.tableColumns[this.props.tableColumns.length - 1]['dataIndex'] !== 'action') {
            this.props.tableColumns[this.props.tableColumns.length] = {
                dataIndex: 'action',
                scopedSlots: {
                    customRender: 'action',
                },
                title: 'Action',
            };
        }
        this.props.tableColumns?.forEach((element: { [x: string]: any; }, index: any) => {
            if (element['dataIndex'] !== 'action') {
                this.props.tableColumns[index] = {
                    title: element['title'],
                    dataIndex: element['dataIndex'],
                    key: element['dataIndex'],
                    newRowDefault: element['newRowDefault'],
                    scopedSlots: {
                        customRender: 'customRender',
                    },
                };
            }
            if (element['editable']) {
                this.editableData.push(element['dataIndex']);
                this.editableTable = true;
            }
            if (element['searchable']) {
                this.props.tableColumns[index] = {
                    title: element['title'],
                    dataIndex: element['dataIndex'],
                    key: element['dataIndex'],
                    newRowDefault: element['newRowDefault'],
                    scopedSlots: {
                        filterDropdown: 'filterDropdown',
                        filterIcon: 'filterIcon',
                        customRender: 'customRender',
                    },
                    onFilter: (value: string, record: { [x: string]: { toString: () => string; }; }) =>
                        record[element['dataIndex']]
                            .toString()
                            .toLowerCase()
                            .includes(value.toLowerCase()),
                };
            }
            if (element['sortable']) {
                this.props.tableColumns[index]['sorter'] = (a: any, b: any) => {
                    if (!isNaN(a[element['dataIndex']]) && !isNaN(b[element['dataIndex']])) {
                        return a[element['dataIndex']] - b[element['dataIndex']];
                    } else {
                        return a[element['dataIndex']].toString().localeCompare(b[element['dataIndex']].toString());
                    }
                };

                if (element['defaultOrder']) {
                    this.props.tableColumns[index]['defaultSortOrder'] = element['defaultOrder'].toString();
                }
            }
        });
        this.props.tableData?.forEach((element: { [x: string]: any; }, index: any) => {
            this.props.tableData[index]['key'] = index + 1;
        });
    }

    protected beforeDestroy() {
        EventBus.$off('requestValue', this.submitValue);
    }
    private handleSearch(selectedKeys: any[], confirm: () => void, dataIndex: any) {
        confirm();
        this.props.searchText = selectedKeys[0];
        this.props.searchedColumn = dataIndex;
    }

    private handleReset(clearFilters: () => void) {
        clearFilters();
        this.props.searchText = '';
    }

    private handleAdd() {
        this.newRow = {};
        let maxKey = 0;
        this.props.tableData?.forEach((element: { [x: string]: any; }, index: any) => {
            if (element['key'] > maxKey) {
                maxKey = element['key'];
            }
        });
        this.props.tableColumns?.forEach((element: { [x: string]: any; }, index: any) => {
            if (element.newRowDefault) {
                this.newRow[element['dataIndex']] = element.newRowDefault;
            } else {
                this.newRow[element['dataIndex']] = 'abc';
            }
        });
        this.newRow['key'] = maxKey + 1;

        this.props.tableData.push(this.newRow);
    }
    private edit(key: number, column: string, text: string) {
        this.currentlyEditable = key + ',' + column;
    }

    private save(record: LooseObject, column: LooseObject, text: string, index: number) {
        this.currentlyEditable = '';
    }

    private deleteRow(rowKey: number) {
        const rowIndex = this.props.tableData.map((e: { key: any; }) => e.key).indexOf(rowKey);
        // alert("Deleting row " + key);
        this.props.tableData.splice(rowIndex, 1);
    }

    private resolveAction(action: string, journey: LooseObject, record: LooseObject) {
        if (journey?.name) {
            let journeyName: string = journey.name;
            if (record.applicantidentifier) {
                store.commit('updateApplicantIdentifier', record.applicantidentifier.toUpperCase());
            } else {
                store.commit('updateApplicantIdentifier', undefined);
            }
            if (record.applicationidentifier) {
                store.commit('updateApplicationIdentifier', record.applicationidentifier.toUpperCase());
            } else {
                store.commit('updateApplicationIdentifier', undefined);
            }
            const nameMatch = journeyName.match(new RegExp(/(\{{[^{}]*\}})/g));
            if (nameMatch?.length !== undefined && nameMatch.length > 0) {
                nameMatch?.forEach((element: string) => {
                    journeyName = journeyName.replace(element, record[element.substring(2, element.length - 2)]);
                });
            }
            const journeyData: LooseObject = {};
            if (journey.data) {
                journey.data.forEach((element: { name: string, value: any; }, index: any) => {
                    let newValue = element.value;
                    const dataMatch = newValue.match(new RegExp(/(\{{[^{}]*\}})/g));
                    if (dataMatch?.length !== undefined && dataMatch.length > 0) {
                        dataMatch?.forEach((matchElement: string) => {
                            newValue = newValue.replace(
                                matchElement,
                                record[matchElement.substring(2, matchElement.length - 2)],
                            );
                        });
                    }
                    journeyData[element.name] = newValue;
                });
                journey.data = journeyData;
            }
            store.commit('updateTrigger', journeyName);
            EventBus.$emit('resolveTrigger', false, { journeyData });
        } else {
            const match = action.match(new RegExp(/(\{{[^{}]*\}})/g));
            match?.forEach((element) => {
                action = action.replace(element, record[element.substring(2, element.length - 2)]);
            });
            window.open(action, '_blank');
        }
    }

    private getWindowWidth() {
        let tableWidth = 0;
        if (this.props.tableColumns) {
            tableWidth = tableWidth + this.props.tableColumns.length;
        }
        if (this.props.actionColumns) {
            tableWidth = tableWidth + this.props.actionColumns.length;
        }
        if (window.innerWidth < tableWidth * 175) {
            return { x: tableWidth * 130 };
        } else {
            return {};
        }
    }

    private updateHeight() {
        this.$nextTick(() => {
            EventBus.$emit('update-form-panel-height');
            EventBus.$emit('update-side-panel-scroll');
        });
    }

    private isValueValid() {
        if (this.props.required && !this.props.tableData) {
            return false;
        }
        return true;
    }

    private submitValue() {
        if (this.isValueValid()) {
            EventBus.$emit('submitValue', this.props.elementId, this.props.tableData, this.props.label);
        }
    }

    private updateInputValues(inputValue: string) {
        this.props.tableData = inputValue;
        this.$emit('update:value', inputValue);
    }

    private checkForEditValue() {
        if (this.props.defaultEditValue !== null) {
            this.updateInputValues(this.props.defaultEditValue.toString());
        } else {
            this.updateInputValues('');
        }
    }
}
